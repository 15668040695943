import { LitElement, html, css } from 'lit';
import '../../elements/katapult-elements/katapult-icon.js';
import { repeat } from 'lit/directives/repeat.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
export class MapLayersMenuView extends LitElement {
  static styles = [
    css`
      .mapLayerSelectable {
        height: 18px;
        width: 18px;
        padding: 0px;
        margin-left: 5px;
        color: var(--paper-grey-300);
        flex-shrink: 0;
      }
      .mapLayerSelectable[name='active'] {
        color: var(--primary-color);
      }
      #layerItemContainer {
        display: flex;
        flex-direction: column;
      }
      sl-checkbox::part(control--checked) {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
    `
  ];

  static properties = {
    startingLayers: { type: Array },
    groupingProperties: { type: Array },
    layerItems: { type: Array },
    geoJsonLayers: { type: Array },
    multiJobIds: { type: Object },
    overlays: { type: Object },
    apiLayerGroups: { type: Object }
  };

  render() {
    return html`
      <div id="layerItemContainer">
        ${repeat(this.layerItems, (layerItem) => {
          if (layerItem.groupedLayers) return this.renderLayerGroup(layerItem);
          else return this.renderLayer(layerItem);
        })}
      </div>
    `;
  }

  renderLayer(layer) {
    return html`
      <div style="padding:10px; display:flex;">
        <paper-checkbox
          .checked="${layer?.state?.checked ?? false}"
          @checked-changed=${(e) => this._dispatchToggleMapLayerItem(e, layer)}
        ></paper-checkbox>
        <katapult-button
          id=${layer.$key}
          size="20"
          textColor=""
          iconOnly
          noBorder
          class="mapLayerSelectable"
          icon="arrow_selector_tool"
          .hidden=${!layer?.state?.checked ?? false}
          name=${layer?.state?.selectable ?? ''}
          @click=${(e) => this._dispatchToggleMapLayerSelectable(e, layer)}
        ></katapult-button>
        <paper-tooltip for="${layer.$key}" position="right" offset="10">Selectable</paper-tooltip>
        <span style="margin:0 5px;">${layer.name ?? layer.$val ?? 'No Layer Name Found'}</span>
      </div>
    `;
  }

  renderLayerGroup(layerGroup) {
    return html`
      <sl-tree @sl-selection-change=${this._unselectAllTreeItems}>
        <sl-tree-item>
          <div style="display: flex; flex-direction: row; align-items: center">
            <paper-checkbox
              .checked=${layerGroup?.state?.checked ?? false}
              @checked-changed=${(e) => this._dispatchToggleMapLayerItem(e, layerGroup)}
            ></paper-checkbox>
            <span>${`${layerGroup.name} (${layerGroup.groupedLayers.length})`}</span>
          </div>
          ${repeat(layerGroup.groupedLayers, (layer) => {
            return html` <sl-tree-item>${this.renderLayer(layer)}</sl-tree-item> `;
          })}
        </sl-tree-item>
      </sl-tree>
    `;
  }

  constructor() {
    super();
    this.startingLayers = [];
    this.groupingProperties = null;
    this.layerItems = [];
    this.geoJsonLayers = [];
    this.multiJobIds = {};
    this.overlays = {};
    this.apiLayerGroups = {};
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('startingLayers') || changedProperties.has('groupingProperties')) {
      this.refreshLayerItems();
    }
  }

  /**
   * Public function that takes the given starting layers, breaks them up by group, then sets the layer items for rendering
   */
  refreshLayerItems() {
    const layerItems = this.groupLayersByProperties(this.startingLayers, this.groupingProperties);
    this.layerItems = layerItems ?? [];
  }

  /**
   * Organizes the given items by their groups.  Each group appears as its own object in the returned data.
   * Groups with the same given property will be combined together and placed under the "groupedLayers"
   * key of the group object.
   * @param {object[]} layers - The layers to group
   * @param {string[]} properties - The property to group the layers by
   * @param {object} [options={}] - Options for grouping the layers
   * @param {boolean} [options.alwaysIncludeLayerAsSingle=false] - If true, layers will be added to the group object AND
   * as a single layer entry in the returned data.
   * @returns {object[]} - The grouped layers
   */
  groupLayersByProperties(layers, properties, options = {}) {
    if (!layers || !properties) return layers;
    const alwaysIncludeLayerAsSingle = options.alwaysIncludeLayerAsSingle ?? false;
    // get all of the api layer groups to display in the layer manager
    const apiLayerGroupLookup = {};
    const singleApiLayers = [];
    layers.forEach((layer) => {
      const propertyToUse = this.getPropertyToGroupBy(layer, properties);
      const groupKey = layer[propertyToUse];
      const layerType = layer.type;
      // if there is a group key, add the layer to the proper group
      if (groupKey != null) {
        if (!apiLayerGroupLookup[groupKey]) {
          // get the groups name
          let groupName = 'No Group Name';
          if (propertyToUse == 'api_layer_group') {
            const groupData = this.apiLayerGroups?.[groupKey];
            if (groupData) groupName = groupData.value;
          }
          apiLayerGroupLookup[groupKey] = { name: groupName, type: layerType, groupedLayers: [], icons: ['maps:layers'] };
        }
        apiLayerGroupLookup[groupKey].groupedLayers.push(layer);
        // include it as a single layer as well if there is an option
        if (alwaysIncludeLayerAsSingle) singleApiLayers.push(layer);
      }
      // if there is not a group key, add the layer a list of layers with no group
      else singleApiLayers.push(layer);
    });
    const sortedLayerGroups = Object.values(apiLayerGroupLookup).sort((a, b) => a.name - b.name);
    const sortedApiLayersWithNoGroup = singleApiLayers.sort((a, b) => a.name - b.name);
    return [...sortedLayerGroups, ...sortedApiLayersWithNoGroup];
  }

  /**
   * Retrieves the first non-null property value from a list of properties for a given layer.
   *
   * @param {Object} layer - The layer object to search for properties.
   * @param {string[]} properties - An array of property names to check in the layer object.
   * @returns {*} - The value of the first non-null property found, or null if none are found.
   */
  getPropertyToGroupBy(layer, properties) {
    for (const property of properties) {
      if (layer[property] != null) return property;
    }
    return null;
  }

  /**
   * Exists solely to ensure that a shoelace tree is not selectable.
   * It loops through all of the tree items and sets their selected state to "false"
   */
  _unselectAllTreeItems(e) {
    const tree = e.currentTarget;
    const treeItems = tree.querySelectorAll('sl-tree-item');
    treeItems.forEach((item) => (item.selected = false));
  }

  /**
   * Public function to refresh the state of all layer items.
   * Loops through all of the layer items and refreshes their state.  If the layer item is a layer group, it will refresh the state of each layer in the group.
   */
  refreshStateForAllLayerItems() {
    this.layerItems.forEach((layerItem) => {
      // if the layer item is a group, refresh the state for each layer in the group and then set the "checked" state of the layer group
      if (layerItem.groupedLayers) {
        layerItem.groupedLayers.forEach((layer) => this._refreshStateForLayer(layer));
        // set the "checked" state of the group from the newly refreshed layers
        const layerGroupChecked = layerItem.groupedLayers.every((layer) => layer.state.checked);
        if (layerItem.state == null) layerItem.state = {};
        layerItem.state.checked = layerGroupChecked;
      }
      // if the layer item is not a group, refresh the state for the single layer
      else this._refreshStateForLayer(layerItem);
    });
    // Force the dom to update
    this.layerItems = [...this.layerItems];
  }

  /**
   * Refreshes the state (checked, selectable) of a given layer using helper functions.
   * IMPORTANT: This function adds a state property to the layer passed in
   * @param {object} layer - The layer to refresh the state for
   */
  _refreshStateForLayer(layer) {
    // ensure we have all data necessary to run the operation
    const layerKey = layer.$key;
    if (layerKey == null) return;
    if (layer.state == null) layer.state = {};

    // set the "checked" state of the layer
    const layerIsOn = this._layerIsOn(layer);
    layer.state.checked = layerIsOn;

    // set the "selectable" state of the layer
    const selectable = this._layerIsSelectable(layer);
    layer.state.selectable = selectable;
  }

  /**
   * Determines if a given layer is currently turned on by checking the multiJobIds, geoJsonLayers, and overlays.
   * @param {object} layer - The layer to evaluate
   * @returns {boolean} - True if the layer is on, false if it is off
   */
  _layerIsOn(layer) {
    const layerKey = layer?.$key;
    if (layerKey == null) return false;
    return !!(
      this.multiJobIds?.[`__ref${layerKey}`] ??
      this.geoJsonLayers.find((x) => x.key == layerKey) ??
      this.overlays?.[layerKey]?.getMap()
    );
  }

  /**
   * Determines if a given layer is selectable by checking the geoJsonLayers, overlays, and multiJobIds.
   * @param {object} layer - The layer we should evaluate
   * @returns {string} - Returns "active" if the layer is selectable, otherwise returns an empty string
   */
  _layerIsSelectable(layer) {
    const layerKey = layer.$key;
    //Runs several times on page load, runs on layer deletion, runs several times on layer add, runs on layer toggle
    // Determine which layer the key is a part of to check selectable
    const geoJsonLayer = this.geoJsonLayers.find((x) => x.key == layerKey);
    const overlay = this.overlays[layerKey];
    if (overlay) return overlay?.clickable ? 'active' : '';
    if (geoJsonLayer) return geoJsonLayer?.selectable ? 'active' : '';
    // Check if the layer is a reference layer
    if (this.multiJobIds[`__ref${layerKey}`]) {
      // Active if the layer's selectable property is true
      return this.multiJobIds[`__ref${layerKey}`]?.selectable === false ? '' : 'active';
    }
    // Assume active
    return 'active';
  }

  /**
   * Dispatched an event to katapult-maps-desktop to toggle a map layer item.
   * @param {object} e - Event data for the triggering checkbox
   * @param {object} layerItem - The layer item associated with checkbox that triggered the event
   */
  _dispatchToggleMapLayerItem(e, layerItem) {
    const checked = e.detail.value;
    const toggleMapLayerEvent = new CustomEvent('toggle-map-layer-item', {
      bubbles: true,
      composed: true,
      detail: { item: layerItem, checked }
    });
    this.dispatchEvent(toggleMapLayerEvent);
  }

  /**
   * Dispatched an event to katapult-maps-desktop to toggle the selectable state of a map layer.
   * @param {object} e - Event data for the triggering checkbox
   * @param {object} layer - The layer associated with click that triggered the event
   */
  _dispatchToggleMapLayerSelectable(e, layer) {
    const isSelectable = e.currentTarget.getAttribute('name') == 'active';
    const toggleMapLayerEvent = new CustomEvent('toggle-map-layer-selectable', {
      bubbles: true,
      composed: true,
      detail: { layer, isSelectable }
    });
    this.dispatchEvent(toggleMapLayerEvent);
  }
}
customElements.define('map-layers-menu-view', MapLayersMenuView);
